<template>
  <div>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.dialogUpload" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="uploadPercentage"
            color="white"
          >
            {{uploadPercentage}}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      v-model="vDataTable.selected"
      :headers="headers"
      :items="vDataTable.data"
      :search="vDataTable.search"
      fixed-header
      :page.sync="vDataTable.options.page"
      :itemsPerPage.sync="vDataTable.options.itemsPerPage"
      :options.sync="vDataTable.options"
      :height="$vuetify.breakpoint.height - 123 - offsetAppBar"
      :footer-props="{
        itemsPerPageText: $t('itemsPerPageText'),
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="register_skey"
      class="fill-height">
      
      <template v-slot:header.data-table-select="{ on, props }">
        <tr>
          <td>
            <v-simple-checkbox :ripple="false" v-bind="props" v-on="on"></v-simple-checkbox>
          </td>
          <td v-if="vDataTable.selected.length > 0">
            {{vDataTable.selected.length}}
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar height="100%">
          <v-toolbar-title>{{'จัดการข้อมูลการสมัคร'}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-container height="100%">
            <v-row dense flat>
              <v-col cols="2" sm="2" md="4" lg="4">
                <v-dialog
                  ref="dialogReceivedDateFromTo"
                  v-model="dialog.dialogReceivedDateFromTo"
                  :return-value.sync="criteria.ReceivedDateFromTo"
                  persistent
                  width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense
                      hide-details
                      outlined
                      v-model="dateRangeText"
                      label="Register Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="criteria.ReceivedDateFromTo"
                    :selected-items-text="selectDate"
                    range
                    scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog.dialogReceivedDateFromTo = false">{{$t('RegisterMaint.Cancel')}}</v-btn>
                    <v-btn text color="primary" @click="$refs.dialogReceivedDateFromTo.save(criteria.ReceivedDateFromTo)">{{$t('RegisterMaint.Ok')}}</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="2" sm="2" md="2" lg="2">
                <v-autocomplete dense hide-details
                  label="Status"
                  :items="statusListAll"
                  v-model="status"
                  item-text="status_name"
                  item-value="status"
                  outlined
                ></v-autocomplete>
              </v-col> 
              <v-col cols="2" sm="2" md="5" lg="5">         
              <v-btn outlined color="primary" style="text-transform: capitalize" @click="retrieveRegister(appName)">
                <img src="../../assets/SoftwareClinicIcon/Find.png" height="30" weight="30"/>{{$t('find')}}
              </v-btn>
              <v-divider class="mx-1" inset vertical></v-divider>
              <v-btn outlined color="primary" style="text-transform: capitalize" @click="clearData(appName)">
                <img src="../../assets/SoftwareClinicIcon/trash.png" height="30" weight="30"/>{{$t('RegisterMaint.clearData')}}
              </v-btn>
              <v-divider class="mx-1" inset vertical></v-divider>
              <v-btn v-show="profile_name !== 'outsource'" outlined color="primary" style="text-transform: capitalize" @click="exportExcel">
                <img src="../../assets/SoftwareClinicIcon/Office-excel-xls-icon.png" height="30" weight="30"/>Export
              </v-btn>
              </v-col>
            </v-row>
            <v-row dense flat>
              <v-col cols="2" sm="2" md="3" lg="3">
                <v-text-field
                  outlined dense hide-details
                  :label="$t('RegisterMaint.firstName')"
                  v-model="firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="3" lg="3">
                <v-text-field
                  outlined dense hide-details
                  :label="$t('RegisterMaint.lastName')"
                  v-model="lastName"
                ></v-text-field>
              </v-col> 
              <v-col cols="2" sm="2" md="2" lg="2">
                <v-text-field
                  outlined dense hide-details
                  :label="$t('RegisterMaint.idCard')"
                  v-model="idCard"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2" lg="2">
                <v-text-field
                  outlined dense hide-details
                  :label="$t('RegisterMaint.Tel')"
                  v-model="tel"
                ></v-text-field>
              </v-col> 
            </v-row>
           </v-container>      
           <v-dialog v-model="dialog.dialogAddEditWindow" max-width="100%" max-height="100%" class="ma-0 pa-0" eager persistent>
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize">
                <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('ProductMaint.buttonNewProduct')}}
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>             
                  <v-row dense flat>
                    <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                    :class="{ 'my-text-style': !vDataTable.editedItem.id_card }"
                    dense
                    hide-details
                    outlined
                    v-model="vDataTable.editedItem.product_skey"
                    :items="productList"
                    :search-input.sync="productName"
                    item-text="product_name"
                    item-value="skey"
                    >
                      <template v-slot:label>
                        <span class="input__label text-title-1">{{ $t('Register.product') }}</span>
                      </template>              
                    </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-select 
                        outlined dense hide-details
                        :items="statusList"
                        :label="$t('RegisterMaint.Status')"
                        v-model="vDataTable.editedItem.status"
                        item-text="status_name"
                        item-value="status"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense flat>
                    <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                      <v-radio-group v-model="vDataTable.editedItem.company_flag" row dense hide-details>
                        <v-radio class='text--primary' @change="active_tab = 0" 
                          v-show="(vDataTable.editedIndex != -1 && vDataTable.editedItem.company_flag == 'N') || vDataTable.editedIndex == -1"
                          value="N"
                        >
                          <template v-slot:label>
                            <div><span class='text--primary'>{{ $t('RegisterMaint.Individual') }}</span></div>
                          </template>
                        </v-radio>
                        <v-radio @change="active_tab = 1"
                          v-show="(vDataTable.editedIndex != -1 && vDataTable.editedItem.company_flag == 'Y') || vDataTable.editedIndex == -1"
                          value="Y"
                        >
                          <template v-slot:label>
                            <div><span class='text--primary'>{{ $t('RegisterMaint.Company') }}</span></div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row dense flat>
                  <v-tabs center-active class="ma-0 pa-0" dense flat v-model="active_tab">
                    <v-tab 
                      v-model="tabPerson"
                      ref="tabPerson"
                      v-show="vDataTable.editedItem.company_flag != 'Y'">
                      ข้อมูลบุคคลธรรมดา
                    </v-tab>
                      <v-tab-item eager v-show="vDataTable.editedItem.company_flag != 'Y'">
                        <v-card>
                          <v-row class="mt-0 pa-0" >
                            <v-col cols="12" sm="12" md="2">
                              <img :src="utils.imageProfile(idCardBase64)" id='thumb_image' height="150" weight="150"/>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <v-row dense flat>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                        :class="{ 'my-text-style': !vDataTable.editedItem.id_card }"
                                        outlined dense hide-details
                                        v-model="vDataTable.editedItem.id_card"
                                        maxlength="13"
                                        @keydown.enter.prevent="retrieveFromIDCard(vDataTable.editedItem.id_card)"
                                      >
                                        <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.idCard') }}</span>
                                        </template>
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-combobox
                                        outlined dense hide-details
                                        :label="$t('RegisterMaint.prefix')"
                                        v-model="vDataTable.editedItem.prefix"
                                        :items="prefixList"
                                        :background-color=" !vDataTable.editedItem.prefix ? 'rgb(255, 206, 215)' : 'white'"
                                      >
                                        <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.prefix') }}</span>
                                        </template>
                                      </v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.first_name }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.first_name"
                                    >
                                    <template v-slot:label>
                                      <span class="input__label text-title">{{ $t('RegisterMaint.firstName') }}</span>
                                    </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.last_name }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.last_name"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.lastName') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="4" sm="4" md="4">  
                                    <v-menu
                                      v-model="menuBirthday"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        :class="{ 'my-text-style': !vDataTable.editedItem.birthday_new }"
                                          v-model="formatThaiDate"
                                          :label="$t('RegisterMaint.birthDay')"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          outlined
                                          dense flat hide-details
                                        >
                                        <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.birthDay') }}</span>
                                        </template>
                                        </v-text-field>
                                      </template>
                                      <v-date-picker  class="ma-0 pa-0"
                                        v-model="vDataTable.editedItem.birthday_new"
                                        @input="menuBirthday = false"
                                        :first-day-of-week="1"
                                        locale="th-TH"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                </v-row>
                            </v-col>
                          </v-row>
                          <v-row dense flat>
                            <v-col dense flat>
                                <v-row dense flat>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.house_no }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.house_no"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.HouseNo') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.village_no }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.village_no"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Moo') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.alley }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.alley"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Trog') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.lane }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.lane"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Soi') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.road }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.road"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Road') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.sub_district_name }"
                                      class="bg-editor"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.sub_district_id"
                                      :items="filterSubDistrict"
                                      :search-input.sync="subDistrictName"
                                      item-text="name_th"
                                      item-value="id"
                                      :background-color=" vDataTable.editedItem.sub_district_id === 0 || !vDataTable.editedItem.sub_district_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.tambon') }}</span>
                                      </template>              
                                    </v-autocomplete>
                                    </v-col>                                
                                  <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.district_name }"
                                      class="bg-editor"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.district_id"
                                      :items="filterDistrict"
                                      :search-input.sync="districtName"
                                      item-text="name_th"
                                      item-value="id"
                                      @change="resetSubdistrict"
                                      :background-color=" vDataTable.editedItem.district_id === 0 || !vDataTable.editedItem.district_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.amphur') }}</span>
                                      </template>              
                                    </v-autocomplete>       
                                  </v-col>
                                    <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.province_id }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.province_id"
                                      :items="provinceList"
                                      :search-input.sync="provinceName"
                                      item-text="name_th"
                                      item-value="id"
                                      @change="resetDistrictSubdistrict"
                                      :background-color=" vDataTable.editedItem.province_id === 0 || !vDataTable.editedItem.province_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.provinces') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.phone }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.phone"
                                      maxlength="10"
                                      :readonly="vDataTable.editedIndex != -1"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Tel') }}</span>
                                      </template> 
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.email }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.email"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.EMAIL') }}</span>
                                      </template> 
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <vuetify-money
                                    :background-color=" !vDataTable.editedItem.credit_limit ? 'rgb(255, 206, 215)' : 'white'"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.credit_limit"
                                      v-bind:options="optionsCreditLimit"
                                      v-bind:label="$t('RegisterMaint.CreditLimit')"
                                      v-bind:valueWhenIsEmpty="null"
                                      v-bind:properties="properties"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.CreditLimit') }}</span>
                                      </template> 
                                    </vuetify-money>
                                    <!-- <v-text-field
                                    :background-color=" !vDataTable.editedItem.credit_limit ? 'rgb(255, 206, 215)' : 'white'"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      prefix="฿"
                                      v-model="vDataTable.editedItem.credit_limit"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.CreditLimit') }}</span>
                                      </template> 
                                    </v-text-field> -->
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.guarantee_skey }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.guarantee_skey"
                                      :items="guaranteeList"
                                      :search-input.sync="guaranteeName"
                                      item-text="guarantee_name"
                                      item-value="skey"
                                      :background-color=" !vDataTable.editedItem.guarantee_skey ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Guarantee') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.guarantee_province_id }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.guarantee_province_id"
                                      :items="provinceList"
                                      :search-input.sync="guaranteeProvinceName"
                                      item-text="name_th"
                                      item-value="id"
                                      :background-color=" !vDataTable.editedItem.guarantee_province_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.GuaranteeProvince') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.deed_no }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.deed_no"
                                      :background-color=" !vDataTable.editedItem.deed_no ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.DeedNo') }}</span>
                                      </template> 
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :background-color=" !vDataTable.editedItem.convenient_time ? 'rgb(255, 206, 215)' : 'white'"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.convenient_time"
                                      :items="convenientTimeList"
                                      :search-input.sync="convenientTimeName"
                                      item-text="convenient_time_name"
                                      item-value="skey"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.ConvenientTime') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :background-color=" !vDataTable.editedItem.receive_channel ? 'rgb(255, 206, 215)' : 'white'"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.receive_channel"
                                      :items="receiveChannelList"
                                      :search-input.sync="receiveChannelName"
                                      item-text="receive_channel_name"
                                      item-value="skey"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.ReceiveChannel') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.reject_skey }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.reject_skey"
                                      :items="rejectList"
                                      :search-input.sync="rejectName"
                                      item-text="reject_name"
                                      item-value="skey"
                                      :readonly="vDataTable.editedItem.status === 'OP' || vDataTable.editedItem.status === 'AS'
                                      || vDataTable.editedItem.status === 'PR' || vDataTable.editedItem.status === 'SU'
                                      || vDataTable.editedItem.status === 'AP' || vDataTable.editedItem.status === 'CO'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Reject') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12">
                                    <div>
                                      <v-textarea
                                      readonly
                                      rows="4"
                                      outlined dense hide-details
                                      maxlength="255"
                                      v-model="vDataTable.editedItem.remark"
                                      @click = handleButtonRemarkClick
                                      >
                                        <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.remark') }}</span>
                                        </template> 
                                      </v-textarea>
                                      <!-- <v-btn  v-btn outlined color="primary"  class="align-right" @click="handleButtonRemarkClick">
                                        {{ $t('RegisterMaint.addRemark') }}                           
                                     </v-btn> -->
                                  </div>
                                  </v-col>
                                </v-row>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-tab-item>
                    <v-tab 
                      v-model="tabCompany"
                      ref="tabCompany"
                      v-show="vDataTable.editedItem.company_flag == 'Y'">
                      ข้อมูลบริษัท
                    </v-tab>
                      <v-tab-item eager v-show="vDataTable.editedItem.company_flag == 'Y'">
                        <v-card>
                          <v-row class="mt-0 pa-0" >
                            <v-col cols="12" sm="12" md="2">
                              <img :src="utils.imageProfile(idCardBase64)" id='thumb_image' height="150" weight="150"/>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <v-row dense flat>
                                  <v-col cols="12" sm="12" md="6">
                                      <v-combobox
                                        outlined dense hide-details
                                        :label="$t('RegisterMaint.prefix')"
                                        v-model="vDataTable.editedItem.prefix"
                                        :items="companyPrefixList"
                                        :background-color=" !vDataTable.editedItem.prefix ? 'rgb(255, 206, 215)' : 'white'"
                                      >
                                        <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.companyPrefix') }}</span>
                                        </template>
                                      </v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                      :class="{ 'my-text-style': !vDataTable.editedItem.company_name }"
                                        outlined dense hide-details
                                        v-model="vDataTable.editedItem.company_name"
                                      >
                                        <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.companyName') }}</span>
                                      </template>
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                      :class="{ 'my-text-style': !vDataTable.editedItem.company_id }"
                                        outlined dense hide-details
                                        v-model="vDataTable.editedItem.company_id"
                                        maxlength="13"
                                      >
                                        <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.companyId') }}</span>
                                      </template>
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.first_name }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.first_name"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.contactPersonFirstName') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.last_name }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.last_name"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.contactPersonLastName') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.company_contact_phone }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.company_contact_phone"
                                      maxlength="10"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.TelContact') }}</span>
                                      </template> 
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                            </v-col>
                          </v-row>
                          <v-row dense flat>
                            <v-col dense flat>
                                <v-row dense flat>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.house_no }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.house_no"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.HouseNo') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.village_no }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.village_no"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Moo') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.alley }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.alley"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Trog') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.lane }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.lane"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Soi') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.road }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.road"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Road') }}</span>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.sub_district_name }"
                                      class="bg-editor"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.sub_district_id"
                                      :items="filterSubDistrict"
                                      :search-input.sync="subDistrictName"
                                      item-text="name_th"
                                      item-value="id"
                                      :background-color=" vDataTable.editedItem.sub_district_id === 0 || !vDataTable.editedItem.sub_district_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.tambon') }}</span>
                                      </template>              
                                    </v-autocomplete>
                                    </v-col>                                  
                                  <v-col cols="12" sm="12" md="6">
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.district_name }"
                                      class="bg-editor"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.district_id"
                                      :items="filterDistrict"
                                      :search-input.sync="districtName"
                                      item-text="name_th"
                                      item-value="id"
                                      @change="resetSubdistrict"
                                      :background-color=" vDataTable.editedItem.district_id === 0 || !vDataTable.editedItem.district_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.amphur') }}</span>
                                      </template>              
                                    </v-autocomplete>       
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.province_id }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.province_id"
                                      :items="provinceList"
                                      :search-input.sync="provinceName"
                                      item-text="name_th"
                                      item-value="id"
                                      @change="resetDistrictSubdistrict"
                                      :background-color=" vDataTable.editedItem.province_id === 0 || !vDataTable.editedItem.province_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.provinces') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.phone }"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.phone"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Tel') }}</span>
                                      </template> 
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.email }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.email"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.EMAIL') }}</span>
                                      </template> 
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <vuetify-money
                                    :background-color=" !vDataTable.editedItem.credit_limit ? 'rgb(255, 206, 215)' : 'white'"
                                      outlined dense hide-details
                                      v-model="vDataTable.editedItem.credit_limit"
                                      v-bind:options="optionsCreditLimit"
                                      v-bind:label="$t('RegisterMaint.CreditLimit')"
                                      v-bind:valueWhenIsEmpty="null"
                                      v-bind:properties="properties"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.CreditLimit') }}</span>
                                      </template> 
                                    </vuetify-money>
                                    <!-- <v-text-field
                                    :background-color=" !vDataTable.editedItem.credit_limit ? 'rgb(255, 206, 215)' : 'white'"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      prefix="฿"
                                      v-model="vDataTable.editedItem.credit_limit"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.CreditLimit') }}</span>
                                      </template> 
                                    </v-text-field> -->
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.guarantee_skey }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.guarantee_skey"
                                      :items="guaranteeList"
                                      :search-input.sync="guaranteeName"
                                      item-text="guarantee_name"
                                      item-value="skey"
                                      :background-color=" !vDataTable.editedItem.guarantee_skey ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Guarantee') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.guarantee_province_id }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.guarantee_province_id"
                                      :items="provinceList"
                                      :search-input.sync="guaranteeProvinceName"
                                      item-text="name_th"
                                      item-value="id"
                                      :background-color=" !vDataTable.editedItem.guarantee_province_id ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.GuaranteeProvince') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                    :class="{ 'my-text-style': !vDataTable.editedItem.deed_no }"
                                      outlined dense hide-details
                                      name="input-7-4"
                                      v-model="vDataTable.editedItem.deed_no"
                                      :background-color=" !vDataTable.editedItem.deed_no ? 'rgb(255, 206, 215)' : 'white'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.DeedNo') }}</span>
                                      </template> 
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :background-color=" !vDataTable.editedItem.convenient_time ? 'rgb(255, 206, 215)' : 'white'"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.convenient_time"
                                      :items="convenientTimeList"
                                      :search-input.sync="convenientTimeName"
                                      item-text="convenient_time_name"
                                      item-value="skey"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.ConvenientTime') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :background-color=" !vDataTable.editedItem.receive_channel ? 'rgb(255, 206, 215)' : 'white'"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.receive_channel"
                                      :items="receiveChannelList"
                                      :search-input.sync="receiveChannelName"
                                      item-text="receive_channel_name"
                                      item-value="skey"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.ReceiveChannel') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">          
                                    <v-autocomplete
                                    :class="{ 'my-text-style': !vDataTable.editedItem.reject_skey }"
                                      dense
                                      hide-details
                                      outlined
                                      v-model="vDataTable.editedItem.reject_skey"
                                      :items="rejectList"
                                      :search-input.sync="rejectName"
                                      item-text="reject_name"
                                      item-value="skey"
                                      :readonly="vDataTable.editedItem.status === 'OP' || vDataTable.editedItem.status === 'AS'
                                      || vDataTable.editedItem.status === 'PR' || vDataTable.editedItem.status === 'SU'
                                      || vDataTable.editedItem.status === 'AP' || vDataTable.editedItem.status === 'CO'"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label text-title">{{ $t('RegisterMaint.Reject') }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col> 
                                  <v-col cols="12" sm="12" md="12">
                                    <div>
                                      <v-textarea
                                      readonly
                                      rows="4"
                                      outlined dense hide-details
                                      maxlength="255"
                                      v-model="vDataTable.editedItem.remark"
                                      @click = handleButtonRemarkClick
                                      >
                                        <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.remark') }}</span>
                                        </template> 
                                      </v-textarea>
                                      <!-- <v-btn  v-btn outlined color="primary"  class="align-right" @click="handleButtonRemarkClick">
                                        {{ $t('RegisterMaint.addRemark') }}                           
                                     </v-btn> -->
                                  </div>
                                  </v-col>
                                </v-row>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-tab-item>
                    <v-tab>
                      {{ 'เอกสารการสมัคร' }}
                    </v-tab>
                    <v-tab-item>
                      <v-row class="mx-5">
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" md="ุ12" class="text-right"> 
                          <v-row dense flat>
                            <v-col cols="12" sm="12" md="10"> 
                              <v-btn outlined color="primary" @click="addFile">{{$t('RegisterMaint.addFile')}}</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="2"> 
                              <v-btn outlined color="primary" @click="uploadFile">{{$t('RegisterMaint.uploadFile')}}</v-btn>
                            </v-col> -->
                            <v-col cols="12" sm="12" md="2"> 
                              <v-btn outlined color="primary" @click="DownloadZip">{{$t('RegisterMaint.downloadFile')}}</v-btn>
                            </v-col>
                          </v-row>                      
                        </v-col>
                      </v-row>
                      <v-row dense flat>
                        <v-col cols="12" sm="12" md="12" dense flat>
                          <v-list  flat dense>
                            <v-subheader>{{ $t('RegisterMaint.uploadTitle') }}</v-subheader>
                              <v-list-item
                                v-for="(item, i) in uploadDocumentList"
                                :index="i"
                                :key="item.skey"
                                flat
                                dense
                              >
                                <v-list-item-content dense flat>
                                  <v-row row wrap  class="ma-0 pa-0" dense flat>
                                    <v-col cols="12" sm="12" md="3">
                                      <v-select 
                                        outlined dense hide-details
                                        :items="filterUploadMaster"
                                        v-model="item.upload_skey"
                                        item-text="upload_name"
                                        item-value="skey"
                                        :readonly="item.skey !== -1"
                                      >
                                      <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.docName') }}</span>
                                        </template>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="2" sm="12" md="2">
                                      <v-text-field
                                      outlined
                                      flat dense
                                      hide-details
                                      v-model="item.remark"
                                      :readonly="item.skey !== -1"
                                      >
                                      <template v-slot:label>
                                          <span class="input__label text-title">{{ $t('RegisterMaint.remark') }}</span>
                                        </template>
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="12" md="3" align="center" justify="center">
                                      <v-row align="center" justify="center">
                                          <v-col align="center" justify="center" cols="12" sm="12" md="1">
                                            <v-file-input v-if="item.skey === -1"
                                              align="center" justify="center"
                                              v-model="item.picture_base64"
                                              dense flat outlined hide-details
                                              clearable="true" 
                                              hide-input
                                              @change="item.filename = item.picture_base64.name"
                                              accept=".jpg,.jpeg,.bmp,.pdf"                                        
                                              >
                                              <!-- <template v-slot:placeholder>
                                                <span class="input__label text-title">{{ item.filename }}</span>
                                              </template> -->
                                            </v-file-input>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="11">
                                            <v-text-field
                                              align="center" justify="center"
                                              outlined
                                              flat dense
                                              hide-details
                                              v-model="item.filename"
                                            >
                                            <template v-slot:label>
                                                <span class="input__label text-title">{{ $t('RegisterMaint.fileName') }}</span>
                                              </template>
                                            </v-text-field>
                                          </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" dense flat class="my-0">
                                      <div>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="mx-1 my-0" dense flat outlined color="primary" @click="checkDeleteFile(item.skey,vDataTable.editedItem.register_skey,item.upload_skey,i,item.image_path)">
                                              <v-icon v-on="on" large color="primary" width="50px">
                                                {{ 'mdi-delete' }}
                                              </v-icon>                                              
                                            </v-btn>
                                          </template>
                                          <span>{{$t('RegisterMaint.DeleteFile')}}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">                                       
                                            <v-btn v-on="on" v-if="item.skey > -1" class="mx-1 my-0" dense flat outlined color="primary" @click="Download(item.image_path, item.filename)">
                                              <v-icon v-on="on" large color="primary">
                                                {{ 'mdi-download' }}
                                              </v-icon>                                       
                                            </v-btn>
                                          </template>
                                          <span>{{$t('RegisterMaint.DownloadFile')}}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">  
                                            <a v-on="on" v-if="item.skey > -1" class="mx-1 my-0" :href="item.urlPreview" target="_blank">
                                              <v-btn v-on="on" dense flat outlined color="primary">
                                                <v-icon large color="primary">
                                                  {{ 'mdi-magnify' }}
                                                </v-icon>                                          
                                              </v-btn>                                      
                                            </a>
                                          </template>
                                          <span>{{$t('RegisterMaint.PreviewFile')}}</span>
                                        </v-tooltip>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>                 
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions dense flat class="my-0 py-0">
                <v-row row wrap  class="ma-0 pa-0" align="center" justify="center" dense flat>
                  <v-col align="center" justify="center" dense flat>
                    <v-btn dense flat class="mx-3 px-3 my-0 py-0" align="center" justify="center" outlined color="primary" @click="saveRegisterMaint">{{$t('save')}}</v-btn>
                    <v-btn dense flat class="mx-3 px-3 my-0 py-0" align="center" justify="center" outlined color="primary" @click="close">{{$t('cancel')}}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>          
          <v-text-field outlined dense hide-details v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox :value="isSelected" :ripple="false" @input="select($event)" ></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="teal" small class="mr-2" v-bind="attrs" v-on="on" @click="editItem(item)">edit</v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" small class="mr-2" v-bind="attrs" v-on="on" @click="deleteItemConfirm(item)">delete</v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip> -->
      </template>
      <template v-slot:item.status="{ item }">
        <v-select dense hide-details
          :items="statusList"
          v-model="item.status"
          item-text="status_name"
          item-value="status"
          @change="setUpdateFlag(item)"
        ></v-select>
      </template>
      <template v-slot:item.data-table-no="{ item, index }">
        {{((vDataTable.options.page - 1) * vDataTable.options.itemsPerPage) + index + 1}}
      </template>
      <template v-slot:no-data>
        <v-alert type="info" border="bottom" colored-border elevation="2">
          {{$t('noData')}}
        </v-alert>
      </template>
      <!-- <template v-slot:item.channel_customer_type="{ item }">        
        <v-row class="text-center d-flex flex-column align-center justify-center">
          <div>
            <v-icon large color="primary">
            {{ item.channel == 'I'? 'mdi-alpha-i-circle' : 'mdi-alpha-g-circle'}}
            </v-icon>
            <v-icon large color="pink">
            {{ item.customer_type == 'G'? 'mdi-alpha-g-circle' : ''}}
          </v-icon>
          </div>
        </v-row>        
      </template> -->
      <template v-slot:item.company_flag="{ item }">
        <v-icon large>
          {{ item.company_flag == 'Y'? 'mdi-domain' : 'mdi-account' }}
        </v-icon>
      </template>
      <template v-slot:item.is_attach_file="{ item }">
        <v-icon color="primary">
          {{ item.is_attach_file == 'Y'? 'mdi-attachment' : '' }}
        </v-icon>
      </template>
      <template v-slot:no-results>
        <v-alert type="warning">
          {{stringFormat($t('searchNotFound'),vDataTable.search)}}
        </v-alert>
      </template>
      <template v-slot:footer>
        <tr class="sticky-table-footer" style="position: absolute">
          <td>Selected({{vDataTable.selected.length}})s</td> 
        </tr>
      </template>
      
    </v-data-table> 
    <v-dialog v-model="dialog.dialogOTP" max-width="500px" eager >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize">
        <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('ProductMaint.buttonNewProduct')}}
      </v-btn>
    </template> -->
    <v-card  class="text-center my-5 py-5">
      <v-card-title>
        <span class="headline">{{ $t('Register.OTP') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                outlined dense hide-details
                name="input-7-4"
                :label="'Ref.'"
                v-model="otpCode"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="8">
              <v-text-field
                outlined dense hide-details
                name="input-7-4"
                :label="$t('Register.OTP')"
                v-model="otp"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
          <!-- <v-btn outlined color="primary" @click="saveOTP">{{$t('save')}}</v-btn> -->
          <v-btn outlined color="primary" @click="closeOTP">{{$t('cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialog.dialogRemark" max-width="800px" eager >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize">
        <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('ProductMaint.buttonNewProduct')}}
      </v-btn>
    </template> -->
      <v-card  class="text-center my-5 py-5">
        <v-card-title>
          <span class="headline">{{ $t('RegisterMaint.addRemark') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  rows="5"
                  outlined dense hide-details
                  maxlength="500"
                  v-model="remark"
                  >
                    <template v-slot:label>
                      <span class="input__label text-title">{{ $t('RegisterMaint.remark') }}</span>
                    </template> 
                  </v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  v-model="menuRemark"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :class="{ 'my-text-style': !date_remark }"
                      v-model="formatThaiDateRemark"
                      :label="$t('RegisterMaint.dateRemark')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense flat hide-details
                    >
                    <template v-slot:label>
                      <span class="input__label text-title">{{ $t('RegisterMaint.dateRemark') }}</span>
                    </template>
                    </v-text-field>
                  </template>
                  <v-date-picker  class="ma-0 pa-0"
                    v-model="date_remark"
                    @input="menuRemark = false"
                    :first-day-of-week="1"
                    locale="th-TH"
                    :timepicker-options="timepickerOptions"
                  ></v-date-picker>
                  <v-time-picker
                    v-if="date_remark !== null"
                    v-model="time_remark"
                    full-width
                    @input="menu = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn outlined color="primary" @click="saveRemark">{{$t('save')}}</v-btn>
            <v-btn outlined color="primary" @click="closeRemark">{{$t('cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import Base64Binary from 'base64-arraybuffer'
import moment from 'moment'
export default {
  components: {
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      rules: {
        winName: value => {
          const pattern = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/
          return pattern.test(value) || 'Invalid Window Name'
        },
      },
      dialog: {
        dialogProgress: false,
        dialogAddEditWindow: false,
        dialogLanguage: false,
        dialogReceivedDateFromTo: false,
        dialogOTP: false,
        dialogUpload: false,
        dialogRemark: false,
      },
      criteria: {
        /* ReceivedDateFromTo: ['2020-10-01', '2020-10-31'], */
        ReceivedDateFromTo: [new Date(Date.now() - 1209600000).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
      },
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
      progressMessage: '',
      languages: [],
      languageEdit: true,
      statusList: [],
      statusListAll: [],
      status: '',
      phone: '',
      uploadList: [],
      provinceId: 0,
      districtId: 0,
      subDistrictId: 0,
      provinceName: '',
      districtName: '',
      subDistrictName: '',
      provinceList: require('@/data/thai_provinces.json'),
      districtList: require('@/data/thai_amphures.json'),
      subDistrictList: require('@/data/thai_tambons.json'),
      subDistrict: [],
      otp: '',
      otpCode: 'VUEJ',
      refCode: '',
      idCard: '',
      firstName: '',
      lastName: '',
      tel: '',
      tempPhone: '',
      uploadDocumentList: [],
      tabPerson: '',
      tabCompany: '',
      menuBirthday: '',
      menuRemark: '',
      regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      regTelephone: /^((0)(\d{9}))$/,
      active_tab: 0,
      prefixList: ['นาย', 'นาง', 'นางสาว'],
      companyPrefixList: ['บ.', 'บจก.', 'บจ.', 'บมจ.', 'บล.', 'บลจ.'],
      idCardBase64: '',
      successMsg: '',
      guaranteeList: [],
      rejectList: [],
      guaranteeProvinceName: '',
      guaranteeName: '',
      rejectName: '',
      companyConvenientTime: '',
      companyConvenientTimeName: '',
      companyreceiveChannel: '',
      companyReceiveChannelName: '',
      companyCreditLimit: 0,
      optionsCreditLimit: {
        locale: 'th-TH',
        // prefix: '฿',
        suffix: '฿',
        length: 20,
        precision: 0
      },
      properties: {
        class: 'custom-label-color custom-placeholder-color text-title',
        color: 'black'
      },
      companyOptionsCreditLimit: {
        locale: 'th-TH',
        // prefix: '฿',
        suffix: '฿',
        length: 20,
        precision: 0
      },
      companyProperties: {
        class: 'custom-label-color custom-placeholder-color text-title',
        color: 'black'
      },
      remark: '',
      date_remark: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      time_remark: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
      // date_remark: new Date().toISOString(),
      timepickerOptions: {
        use12HourClock: false,
        format: 'HH:mm',
      },
    }
  },

  computed: {
    ...mapGetters(['getShowAppBar', 'getAppBarHeight']),
    ...mapState({
      authLoading: state => state.auth.status === 'loading',
      name: state => `${state.user.profile.first_name} ${state.user.profile.last_name}`,
      userID: state => `${state.user.profile.user_id}`,
      picture_base64: state => `${state.user.profile.picture_base64}`,
      profile_name: state => `${state.user.profile.profile_name}`,
      token: state => `${state.auth.token}`,
    }),
    selectDate () {
      var text = '$vuetify.datePicker.itemsSelected'
      text = 'ช่วงเวลาค้นหาข้อมูล'
      return text
    },
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? this.$t('RegisterMaint.NewRegister') : this.$t('RegisterMaint.Title')
    },
    formLanguageTitle () {
      return this.vDataTable.editedLanguageIndex === -1 ? this.$t('SubscribeMaint.newSubscribeLanguage') : this.$t('SubscribeMaint.editSubscribeLanguage')
    },
    headers () {
      return [
        { text: '#', align: 'center', value: 'register_skey' },
        { text: this.$t('actions'), value: 'actions', width: '40px', sortable: false },
        { text: this.$t('ช่องทาง'), value: 'channel_customer_type', align: 'left', class: 'text-xl-left body-1 black--text', width: '100px' },
        { text: this.$t('ไฟล์แนบ'), value: 'is_attach_file', align: 'left', class: 'text-xl-left body-1 black--text' },
        // { text: this.$t(''), value: 'customer_type', align: 'left', class: 'text-xl-left body-1 black--text', width: '5px' },
        { text: this.$t('ผลิตภัณฑ์'), value: 'product_name', align: 'left', class: 'text-xl-left body-1 black--text', width: '130px' },
        { text: this.$t('บุคคล / นิติบุคคล'), value: 'company_flag', align: 'left', class: 'text-xl-left body-1 black--text', width: '60px' },
        { text: this.$t('คำนำหน้าชื่อ / คำนำหน้าบริษัท'), value: 'prefix', align: 'left', class: 'text-xl-left body-1 black--text', width: '100px' },
        { text: this.$t('ชื่อ-นามสกุล / ชื่อบริษัท'), value: 'full_name', align: 'left', class: 'text-xl-left body-1 black--text', width: '180px' },
        // { text: this.$t('นามสกุล'), value: 'last_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('เลขบัตรประชาชน / เลขนิติบุคคล'), value: 'number13', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('เบอร์โทร / เบอร์โทรผู้ติดต่อ'), value: 'phone_new', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('อำเภอ'), value: 'district_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('จัดหวัด'), value: 'province_name', align: 'left', class: 'text-xl-left body-1 black--text', width: '100px' },
        { text: this.$t('อีเมล'), value: 'email', align: 'left', class: 'text-xl-left body-1 black--text' },
        // { text: this.$t('ตำบล'), value: 'subdistrict_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        // { text: this.$t('รหัสไปรษณีย์'), value: 'zipcode', align: 'left', class: 'text-xl-left body-1 black--text' },
        // { text: this.$t('ไลน์ไอดี'), value: 'line_id', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('รหัสสาขา'), value: 'create_user', align: 'left', class: 'text-xl-left body-1 black--text', width: '60px' },
        // { text: this.$t('ชื่อสาขา'), value: 'branch_name', align: 'left', class: 'text-xl-left body-1 black--text', width: '150px' },
        // { text: this.$t('ยอมรับนโยบายความเป็นส่วนตัว'), value: 'consent_flag', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('วันที่สมัคร'), value: 'create_date_new', align: 'left', class: 'text-xl-left body-1 black--text', width: '170px' },
        { text: this.$t('สถานะ'), value: 'status_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('สาขา'), value: 'branch', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('ที่อยู่'), value: 'address', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('ตำบล'), value: 'tambon', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('อำเภอ'), value: 'amphur', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('จังหวัด'), value: 'provinces', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('รหัสไปรษณีย์'), value: 'zipcode', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('เบอร์โทรสาขา'), value: 'tel', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('ภาค'), value: 'sector', align: 'left', class: 'text-xl-left body-1 black--text' },
      ]
    },
    subHeaders () {
      return [
        { text: this.$t('navWindow.windowName'), value: 'win_name' },
        { text: this.$t('createUser'), value: 'create_user_id' },
      ]
    },
    subHeadersLanguage () {
      return [
        { text: '', align: 'center', value: 'data-table-select' },
        { text: this.$t('actions'), value: 'actions', sortable: false },
        { text: this.$t('language'), value: 'language_comment' },
        { text: this.$t('SubscribeMaint.name'), value: 'name', align: 'right' },
      ]
    },
    offsetAppBar () {
      // return this.getShowAppBar ? 0 : this.getAppBarHeight
      return this.getShowAppBar ? this.getAppBarHeight : 0
    },
    dateRangeText () {
      return this.criteria.ReceivedDateFromTo.join(' ~ ')
    },
    filterProvince () {
      let options = this.provinceList
      return options.filter(o => o.id === this.vDataTable.editedItem.province_id)
    },
    filterDistrict () {
      let options = this.districtList
      return options.filter(o => o.province_id === this.vDataTable.editedItem.province_id)
    },
    filterSubDistrict () {
      let options = this.subDistrictList
      return options.filter(o => o.amphure_id === this.vDataTable.editedItem.district_id)
    },
    filterUploadMaster () {
      let options = this.uploadList
      return options.filter(o => o.company_flag === this.vDataTable.editedItem.company_flag)
    },
    formatThaiDate () {
      var d = new Date(this.vDataTable.editedItem.birthday_new)
      return moment(d).add(543, 'year').format('DD/MM/YYYY')
    },
    formatThaiDateRemark () {
      var d = new Date(this.date_remark + ' ' + this.time_remark)
      return moment(d).add(543, 'year').format('DD/MM/YYYY HH:mm')
    },
  },

  watch: {
    'dialog.dialogAddEditWindow' (val) {
      val || this.close()
    }
  },

  async created () {
    this.clearData()
    await this.getStatusList()
    await this.getStatusListAll()
    // await this.retrieveRegister(this.appName)
    await this.retrieveFrontUpload('th')
    await this.retrieveFrontGuarantee('th')
    await this.retrieveFrontReject('th')
    await this.getProductList('th')
    await this.retrieveFrontConvenientTime('th')
    await this.retrieveFrontReceiveChannel('th')
    this.status = 'ALL'
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    clearData () {
      this.firstName = ''
      this.lastName = ''
      this.tel = ''
      this.idCard = ''
      this.criteria.ReceivedDateFromTo = [new Date(Date.now() - 1209600000).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)]
      this.status = 'ALL'
      this.vDataTable.data = []
    },
    validateReg () {
      var messageCustom = ''
      if (this.vDataTable.editedItem.company_flag !== 'Y') {
        if (!this.validateIdcard(this.vDataTable.editedItem.id_card)) {
          messageCustom = messageCustom + 'เลขที่บัตรประชาชนไม่ถูกต้อง' + '<br />'
        }
        if (!this.validatePhoneNumber(this.vDataTable.editedItem.phone)) {
          messageCustom = messageCustom + 'เบอร์โทรศัพท์ไม่ถูกต้อง' + '<br />'
        }
        var bd = new Date(this.vDataTable.editedItem.birthday_new)
        var currentDt = new Date()
        var dateBd = moment(bd).format('YYYY-MM-DD')
        var date20 = moment(currentDt).add(-20, 'Y').format('YYYY-MM-DD')
        // if (dateBd > date20) {
        if (moment(date20).isBefore(dateBd)) {
          messageCustom = messageCustom + 'ผู้สมัครต้องอายุไม่ต่ำกว่า 20 ปี' + '<br />'
        }
      } else {
        if (!this.validatePhoneNumber(this.vDataTable.editedItem.company_contact_phone)) {
          messageCustom = messageCustom + 'เบอร์โทรศัพท์ผู้ติดต่อไม่ถูกต้อง' + '<br />'
        }
      }
      if (this.vDataTable.editedItem.email) {
        if (!this.validateMail(this.vDataTable.editedItem.email)) {
          messageCustom = messageCustom + 'อีเมลไม่ถูกต้อง' + '<br />'
        }
      }
      if (this.vDataTable.editedItem.status === 'RE' || this.vDataTable.editedItem.status === 'CA') {
        if (this.vDataTable.editedItem.reject_skey <= 0) {
          messageCustom = messageCustom + 'กรุณาระบุเหตุผลการยกเลิก/ปฏิเสธ' + '<br />'
        }
      }
      if (this.uploadDocumentList) {
        var i = 0
        let upload = this.uploadDocumentList
        let unUpload = upload.filter(o => o.upload_skey === -1)
        if (unUpload.length > 0) {
          messageCustom = messageCustom + 'กรุณาเลือกชื่อเอกสาร' + '<br />'
        }
        let unFile = upload.filter(o => o.filename === '')
        if (unFile.length > 0) {
          messageCustom = messageCustom + 'กรุณาเลือกไฟล์' + '<br />'
        }
        var uploadFileNameList = []
        for (i = 0; i < this.uploadDocumentList.length; i++) {
          uploadFileNameList.push(this.uploadDocumentList[i].filename)
        }
        if (this.checkIfDuplicateExists(uploadFileNameList)) {
          messageCustom = messageCustom + 'ไฟล์ที่เลือกต้องไม่ซ้ำกัน' + '<br />'
        }
        var etcFileMaster = []
        var etcFileList = []
        var unSave = upload.filter(o => o.skey === -1 && !o.remark)
        etcFileMaster = this.filterUploadMaster.filter(o => (o.etc_flag === 'Y'))
        etcFileList = unSave.filter((efl) => {
          return etcFileMaster.map((efm) => { return efm.skey }).includes(efl.upload_skey)
        })
        if (etcFileList.length > 0 && etcFileList) {
          messageCustom = messageCustom + 'กรุณากรอกข้อมูลหมายเหตุในชื่อเอกสารอื่นๆ ' + '<br />'
        }
      }
      if (messageCustom) {
        this.$swal({type: 'warning', title: messageCustom, showConfirmButton: true, timer: 2500})
        return false
      } else {
        return true
      }
    },
    checkIfDuplicateExists (arrayList) {
      return new Set(arrayList).size !== arrayList.length
    },
    addFile () {
      this.uploadDocumentList.push({ skey: -1, register_skey: -1, upload_skey: -1, image_path: '', filename: '', remark: '', picture_base64: '', urlPreview: '' })
    },
    deleteFile (index) {
      this.uploadDocumentList.splice(index, 1)
    },
    selectLanguage (language) {
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        win_name: '',
        window_comment: ''
      }
      this.vDataTable.defaultItem = {
        win_name: '',
        window_comment: ''
      }
    },
    addNew () {
      this.dialog.dialogAddEditWindow = true
      this.status = 'OP'
    },
    async getProductList (language) {
      await this.axios.post(process.env.VUE_APP_API + '/product/retrieveProductList' + process.env.VUE_APP_DATABASE_TYPE, {
        lang: language
      })
      .then(response => {
        this.productList = response.data.data
        // console.log(this.product, 'this.product')
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    async updateRefcode () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waitingSave')
      await this.axios.post(process.env.VUE_APP_API + '/register/updateRefcode' + process.env.VUE_APP_DATABASE_TYPE, {
        idCard: this.idCard
      })
        .then(async response => {
          if (response.data.isSuccess) {
            // this.clearFlag()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            this.retrieveRegister()
          } else {
            await this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          await this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    // saveOTP () {
    //   // this.updateRefcode()
    //   // this.$swal({type: 'success', title: 'แก้ไขรหัสอ้างอิงเรียบร้อยแล้ว'})
    //   // this.closeOTP()
    //   // this.resetData()
    //   this.saveRegisterMaint()
    // },
    openOTP (idCard) {
      this.dialog.dialogOTP = true
      this.idCard = idCard
    },
    closeOTP () {
      this.dialog.dialogOTP = false
    },
    async retrieveUploadDocument (registerSkey) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/register/retrieveUploadDocument' + process.env.VUE_APP_DATABASE_TYPE, {
        register_skey: registerSkey
      })
        .then(async response => {
          this.uploadDocumentList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async retrieveFrontUpload (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/upload/retrieveFrontUpload' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.uploadList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveFrontGuarantee (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/guarantee/retrieveFrontGuarantee' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.guaranteeList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveFrontReject (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/reject/retrieveFrontReject' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.rejectList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveFrontReceiveChannel (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/receive_channel/retrieveFrontReceiveChannel' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.receiveChannelList = response.data.data
          console.log(this.receiveChannelList)
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveFrontConvenientTime (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/convenient_time/retrieveFrontConvenientTime' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.convenientTimeList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveFromIDCard (idCard) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/register/retrieveFromIDCard' + process.env.VUE_APP_DATABASE_TYPE, {
        id_card: idCard
      })
        .then(async response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedItem.prefix = response.data.data[0].prefix
            this.vDataTable.editedItem.first_name = response.data.data[0].first_name
            this.vDataTable.editedItem.last_name = response.data.data[0].last_name
            this.vDataTable.editedItem.phone = response.data.data[0].phone
            this.vDataTable.editedItem.house_no = response.data.data[0].house_no
            this.vDataTable.editedItem.village_no = response.data.data[0].village_no
            this.vDataTable.editedItem.alley = response.data.data[0].alley
            this.vDataTable.editedItem.lane = response.data.data[0].lane
            this.vDataTable.editedItem.road = response.data.data[0].road
            this.vDataTable.editedItem.province_id = response.data.data[0].province_id
            this.vDataTable.editedItem.province_name = response.data.data[0].province_name
            this.vDataTable.editedItem.district_id = response.data.data[0].district_id
            this.vDataTable.editedItem.district_name = response.data.data[0].district_name
            this.vDataTable.editedItem.sub_district_id = response.data.data[0].sub_district_id
            this.vDataTable.editedItem.sub_district_name = response.data.data[0].sub_district_name
            this.vDataTable.editedItem.email = response.data.data[0].email
            this.vDataTable.editedItem.zipcode = response.data.data[0].zipcode
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async getStatusList () {
      this.axios.post(process.env.VUE_APP_API + '/register/getStatusList' + process.env.VUE_APP_DATABASE_TYPE, {
        IsAll: false
      })
        .then(async response => {
          this.statusList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async getStatusListAll () {
      this.axios.post(process.env.VUE_APP_API + '/register/getStatusList' + process.env.VUE_APP_DATABASE_TYPE, {
        IsAll: true
      })
        .then(async response => {
          this.statusListAll = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    setUpdateFlag (item) {
      if (item.edit_flag === 0) {
        item.edit_flag = 2
      }
    },
    exportExcel () {
      if (this.vDataTable.data.length > 0) {
        this.axios.post(process.env.VUE_APP_API + '/register/ExportExcel', {data_json: this.vDataTable.data, is_partner: 'N'}
        ).then(response => {
          if (response.data.isSuccess) {
            const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.excel_base64)], {type: 'application/vnd.ms-excel; charset=utf-8'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'ข้อมูลการสมัครสินเชื่อจากเว็บไซต์ มีที่ มีเงิน.xlsx')
            document.body.appendChild(link)
            link.click()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      } else {
        var messageCustom = 'Please select detail.'
        this.$swal({type: 'error', html: messageCustom})
      }
    },
    async saveRegister () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waitingSave')
      console.log(this.vDataTable.data, 'this.vDataTable.selected')
      await this.axios.post(process.env.VUE_APP_API + '/register/saveRegister' + process.env.VUE_APP_DATABASE_TYPE, {
        dataEditRegister: this.vDataTable.data
      })
        .then(async response => {
          if (response.data.isSuccess) {
            // this.clearFlag()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            this.retrieveRegister()
          } else {
            await this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          await this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    newSubscribeLanguage (item) {
      this.vDataTable.editedItem = Object.assign({}, item)
      // this.dialogLanguage = true
      this.languageEdit = false
    },
    expandCollapseNode (expand) {
      if (expand) {
        this.vDataTable.expanded = this.vDataTable.data
      } else {
        this.vDataTable.expanded = []
      }
    },
    findCopyHeader (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      })
    },
    findNodeSelectedLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).selectedLanguage
    },
    async retrieveRegister (appName) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      if (this.criteria.ReceivedDateFromTo.length > 0) {
        this.dateSearchFrom = this.criteria.ReceivedDateFromTo[0]
        if (this.criteria.ReceivedDateFromTo.length > 1) {
          this.dateSearchTo = this.criteria.ReceivedDateFromTo[1]
        } else {
          this.dateSearchTo = new Date().toISOString().slice(0, 10)
        }
      }
      await this.axios.post(process.env.VUE_APP_API + '/register/retrieveRegisterAdmin' + process.env.VUE_APP_DATABASE_TYPE, {
        date_from: this.dateSearchFrom,
        date_to: this.dateSearchTo,
        status: this.status,
        firstName: this.firstName,
        lastName: this.lastName,
        idCard: this.idCard,
        tel: this.tel
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    // checkPhone (phone) {
    //   if (this.tempPhone !== phone) {
    //     this.dialog.dialogOTP = true
    //   } else {
    //     this.saveRegisterMaint()
    //   }
    // },
    async validateCreditLimit (productSkey, creditLimit, companyFlag) {
      await this.axios.post(process.env.VUE_APP_API + '/register/validateCreditLimit' + process.env.VUE_APP_DATABASE_TYPE, {
        productSkey: productSkey,
        creditLimit: creditLimit,
        companyFlag: companyFlag
      })
        .then(async response => {
          if (response.data.isSuccess) {
            // this.dialog.dialogOTP = true
            // this.otp = null
            // this.send()
            if (this.vDataTable.editedIndex > -1) {
              this.updateRegisterMaint(
              this.vDataTable.editedItem.register_skey,
              this.vDataTable.editedItem.product_skey,
              this.productName,
              this.vDataTable.editedItem.prefix,
              this.vDataTable.editedItem.first_name,
              this.vDataTable.editedItem.last_name,
              this.vDataTable.editedItem.phone,
              this.vDataTable.editedItem.id_card,
              this.vDataTable.editedItem.house_no,
              this.vDataTable.editedItem.village_no,
              this.vDataTable.editedItem.alley,
              this.vDataTable.editedItem.lane,
              this.vDataTable.editedItem.road,
              this.vDataTable.editedItem.sub_district_id,
              this.subDistrictName,
              this.vDataTable.editedItem.district_id,
              this.districtName,
              this.vDataTable.editedItem.province_id,
              this.provinceName,
              this.vDataTable.editedItem.email,
              this.vDataTable.editedItem.company_flag,
              this.vDataTable.editedItem.company_name,
              this.vDataTable.editedItem.company_id,
              this.vDataTable.editedItem.company_contact_phone,
              this.vDataTable.editedItem.status,
              this.vDataTable.editedItem.birthday_new,
              this.idCardBase64,
              this.vDataTable.editedItem.remark,
              this.vDataTable.editedItem.credit_limit,
              this.vDataTable.editedItem.guarantee_skey,
              this.guaranteeName,
              this.vDataTable.editedItem.guarantee_province_id,
              this.guaranteeProvinceName,
              this.vDataTable.editedItem.reject_skey,
              this.rejectName,
              this.vDataTable.editedItem.deed_no,
              this.vDataTable.editedItem.convenient_time,
              this.convenientTimeName,
              this.vDataTable.editedItem.receive_channel,
              this.receiveChannelName)
            } else {
              this.addRegisterMaint(this.vDataTable.editedItem.product_skey,
              this.productName,
              this.vDataTable.editedItem.prefix,
              this.vDataTable.editedItem.first_name,
              this.vDataTable.editedItem.last_name,
              this.vDataTable.editedItem.phone,
              this.vDataTable.editedItem.id_card,
              this.vDataTable.editedItem.house_no,
              this.vDataTable.editedItem.village_no,
              this.vDataTable.editedItem.alley,
              this.vDataTable.editedItem.lane,
              this.vDataTable.editedItem.road,
              this.vDataTable.editedItem.sub_district_id,
              this.subDistrictName,
              this.vDataTable.editedItem.district_id,
              this.districtName,
              this.vDataTable.editedItem.province_id,
              this.provinceName,
              this.vDataTable.editedItem.email,
              this.vDataTable.editedItem.company_flag,
              this.vDataTable.editedItem.company_name,
              this.vDataTable.editedItem.company_id,
              this.vDataTable.editedItem.company_contact_phone,
              this.vDataTable.editedItem.birthday_new,
              this.idCardBase64)
            }
            this.retrieveRegister()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async saveRegisterMaint () {
      console.log('save register maint')
      if (this.validateReg() === true) {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        this.validateCreditLimit(this.vDataTable.editedItem.product_skey, this.vDataTable.editedItem.credit_limit, this.vDataTable.editedItem.company_flag)
        // this.close()
        this.dialog.dialogProgress = false
      }
    },
    addRegisterMaint (productSkey, productName, prefix, firstName, lastName, phone, idCard, houseNo, villageNo, alley, lane, road, subDistrictId
      , subDistrictNm, districtId, districtNm, provinceId, provinceNm, email, companyFlag, companyName, companyId, companyContactPhone, birthday, pictureBase64) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      if (!companyName) {
        companyName = ''
      }
      if (!companyId) {
        companyId = ''
      }
      if (!companyContactPhone) {
        companyContactPhone = ''
      }
      if (!prefix) {
        prefix = ''
      }
      if (!idCard) {
        idCard = ''
      }
      if (!birthday) {
        birthday = ''
      }
      if (!companyFlag) {
        companyFlag = 'N'
      }
      if (!houseNo) {
        houseNo = ''
      }
      if (!villageNo) {
        villageNo = ''
      }
      if (!alley) {
        alley = ''
      }
      if (!idCard) {
        idCard = ''
      }
      if (!lane) {
        lane = ''
      }
      if (!road) {
        road = ''
      }
      if (!subDistrictNm) {
        subDistrictNm = ''
      }
      if (!districtId) {
        districtId = ''
      }
      if (!districtNm) {
        districtNm = ''
      }
      if (!provinceId) {
        provinceId = ''
      }
      if (!provinceNm) {
        provinceNm = ''
      }
      if (!email) {
        email = ''
      }
      this.axios.post(process.env.VUE_APP_API + '/register/addRegisterMaint' + process.env.VUE_APP_DATABASE_TYPE, {
        product_skey: productSkey,
        product_name: productName,
        prefix: prefix,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        id_card: idCard,
        house_no: houseNo,
        village_no: villageNo,
        alley: alley,
        lane: lane,
        road: road,
        sub_district_id: subDistrictId,
        sub_district_name: subDistrictNm,
        district_id: districtId,
        district_name: districtNm,
        province_id: provinceId,
        province_name: provinceNm,
        email: email,
        consent_flag: 'Y',
        status: 'OP',
        channel: 'M',
        customer_type: 'M',
        otp_flag: 'Y',
        company_flag: companyFlag,
        company_name: companyName,
        company_id: companyId,
        company_contact_phone: companyContactPhone,
        birthday: birthday,
        picture_base64: pictureBase64
      })
        .then(response => {
          if (response.data.isSuccess) {
            // this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            // this.resetData()
            // this.dialog.dialogPrint = false
            this.vDataTable.editedItem.register_skey = response.data.data.register_skey
            this.vDataTable.editedIndex = response.data.data.register_skey
            this.dialog.dialogProgress = false
            this.uploadFile()
            // his.retrieveRegisterPartner() // this.uploadFile()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
            this.dialog.dialogProgress = false
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
          this.dialog.dialogProgress = false
        })
    },
    updateRegisterMaint (registerSkey, productSkey, productName, prefix, firstName, lastName, phone, idCard, houseNo, villageNo, alley, lane, road, subDistrictId
      , subDistrictNm, districtId, districtNm, provinceId, provinceNm, email, companyFlag, companyName, companyId, companyContactPhone, status, birthday, pictureBase64
      , remark, creditLimit, guaranteeSkey, guaranteeName, guaranteeProvinceId, guaranteeProvinceName, rejectSkey, rejectName, deedNo, convenientTime, convenientTimeName, receiveChannel, receiveChannelName) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      console.log('update register maint')
      if (!companyName) {
        companyName = ''
      }
      if (!companyId) {
        companyId = ''
      }
      if (!companyContactPhone) {
        companyContactPhone = ''
      }
      if (!prefix) {
        prefix = ''
      }
      if (!idCard) {
        idCard = ''
      }
      if (!birthday) {
        birthday = ''
      }
      if (!companyFlag) {
        companyFlag = 'N'
      }
      if (!houseNo) {
        houseNo = ''
      }
      if (!villageNo) {
        villageNo = ''
      }
      if (!alley) {
        alley = ''
      }
      if (!idCard) {
        idCard = ''
      }
      if (!lane) {
        lane = ''
      }
      if (!road) {
        road = ''
      }
      if (!subDistrictNm) {
        subDistrictNm = ''
      }
      if (!districtId) {
        districtId = ''
      }
      if (!districtNm) {
        districtNm = ''
      }
      if (!provinceId) {
        provinceId = ''
      }
      if (!provinceNm) {
        provinceNm = ''
      }
      if (!email) {
        email = ''
      }
      if (!remark) {
        remark = ''
      }
      if (!rejectSkey) {
        rejectSkey = -1
      }
      if (!guaranteeName) {
        guaranteeName = ''
      }
      if (!guaranteeProvinceName) {
        guaranteeProvinceName = ''
      }
      if (!rejectName) {
        rejectName = ''
      }
      if (!deedNo) {
        deedNo = ''
      }
      if (!convenientTime) {
        convenientTime = -1
      }
      if (!convenientTimeName) {
        convenientTimeName = ''
      }
      if (!receiveChannel) {
        receiveChannel = -1
      }
      if (!receiveChannelName) {
        receiveChannelName = ''
      }
      this.axios.post(process.env.VUE_APP_API + '/register/updateRegisterMaint' + process.env.VUE_APP_DATABASE_TYPE, {
        register_skey: registerSkey,
        product_skey: productSkey,
        product_name: productName,
        prefix: prefix,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        id_card: idCard,
        house_no: houseNo,
        village_no: villageNo,
        alley: alley,
        lane: lane,
        road: road,
        sub_district_id: subDistrictId,
        sub_district_name: subDistrictNm,
        district_id: districtId,
        district_name: districtNm,
        province_id: provinceId,
        province_name: provinceNm,
        email: email,
        customer_type: 'M',
        company_flag: companyFlag,
        company_name: companyName,
        company_id: companyId,
        company_contact_phone: companyContactPhone,
        status: status,
        birthday: birthday,
        picture_base64: pictureBase64,
        remark: remark,
        credit_limit: creditLimit,
        guarantee_skey: guaranteeSkey,
        guarantee_name: guaranteeName,
        guarantee_province_id: guaranteeProvinceId,
        guarantee_province_name: guaranteeProvinceName,
        reject_skey: rejectSkey,
        reject_name: rejectName,
        deed_no: deedNo,
        convenientTime: convenientTime,
        convenientTimeName: convenientTimeName,
        receiveChannel: receiveChannel,
        receiveChannelName: receiveChannelName
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.successMsg = response.data.reasonText
            this.dialog.dialogProgress = false
            this.uploadFile()
            // this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            // this.resetData()
            // this.dialog.dialogPrint = false
            // this.retrieveRegister()
            // this.this.vDataTable.editedItem.register_skey = response.data.data.register_skey
            // this.uploadFile()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
            this.dialog.dialogProgress = false
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
          this.dialog.dialogProgress = false
        })
    },
    checkDeleteFile (skey, registerSkey, uploadSkey, ind, imagePath) {
      this.$swal({
        text: this.$t('RegisterMaint.confirmDeleteSelected'),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then((result) => {
        if (result) {
          this.deleteUploadFile(skey, registerSkey, uploadSkey, ind, imagePath)
        }
      })
    },
    async deleteUploadFile (skey, registerSkey, uploadSkey, ind, imagePath) {
      if (skey === -1) {
        this.deleteFile(ind)
        return
      }
      await this.axios.post(process.env.VUE_APP_API + '/register/deleteUploadDocument' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        register_skey: registerSkey,
        upload_skey: uploadSkey,
        image_path: imagePath
      })
        .then(async response => {
          this.deleteFile(ind)
          if (response.data.isSuccess) {
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            // this.resetData()
            // this.dialog.dialogPrint = false
            // his.retrieveRegisterPartner() // this.uploadFile()
            this.retrieveUploadDocument(this.vDataTable.editedItem.register_skey)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async addSubscribe (name, email, tel, pictureBase64, activeFlag) {
      if (!tel) {
        tel = ''
      }
      if (!pictureBase64) {
        pictureBase64 = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/register/addSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
        name: name,
        email: email,
        tel: tel,
        picture_base64: pictureBase64,
        active_flag: activeFlag
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedItem.skey = response.data.data.skey
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.retrieveSubscribe()
            this.$swal({type: 'success', title: response.data.reasonText})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateSubscribe (skey, name, email, tel, pictureBase64, activeFlag) {
      if (!tel) {
        tel = ''
      }
      if (!pictureBase64) {
        pictureBase64 = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/register/updateSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        name: name,
        email: email,
        tel: tel,
        picture_base64: pictureBase64,
        active_flag: activeFlag,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.retrieveSubscribe()
            this.$swal({type: 'success', title: response.data.reasonText})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    checkDeleteselected () {
      if (this.vDataTable.selected.length > 0) {
        this.$swal({
          text: this.$t('SubscribeMaint.confirmDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteSelected()
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageDeleteNotSelected')})
      }
    },
    checkDeleteselectedLanguage (item) {
      if (this.findNodeSelectedLanguage(item.skey).length > 0) {
        this.$swal({
          text: this.$t('SubscribeMaint.confirmSubscribeLanguageDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteLanguageSelected(item)
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messagedeleteSubscribeLanguageNotSelected')})
      }
    },
    async deleteSelected () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.vDataTable.selected, async function (x) {
        this.progressMessage = this.stringFormat(this.$t('SubscribeMaint.progressMessageDelete'), x.name_name)
        var val = await this.deleteNode(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(x), 1)
      }
      this.dialog.dialogProgress = false
    },
    async deleteLanguageSelected (item) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.findNodeSelectedLanguage(item.skey), async function (x) {
        this.progressMessage = this.stringFormat(this.$t('SubscribeMaint.progressMessageNodeLanguageDelete'), x.name)
        var val = await this.deleteLanguage(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        var selectedLanguage = this.findNodeSelectedLanguage(item.skey)
        selectedLanguage.splice(selectedLanguage.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
    },
    async deleteNode (item) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var skey = item.skey
        var valRet
        await this.axios.post(process.env.VUE_APP_API + '/register/deleteSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
          skey: skey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              if (this.vDataTable.data.indexOf(item) >= 0) {
                this.vDataTable.data.splice(this.vDataTable.data.indexOf(item), 1)
              }
              this.$swal({type: 'success', title: response.data.reasonText})
              valRet = item
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
        return valRet
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    newItem () {
      this.vDataTable.editedItem.active_flag = 'Y'
    },
    editItem (item) {
      // console.log(item.register_skey)
      this.tempPhone = item.phone
      this.retrieveUploadDocument(item.register_skey)
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEditWindow = true
      if (this.vDataTable.editedItem.company_flag === 'Y') {
        this.active_tab = 1
      } else {
        this.active_tab = 0
      }
      this.idCardBase64 = item.picture_base64
      if (!this.vDataTable.editedItem.credit_limit) {
        this.vDataTable.editedItem.credit_limit = null
      }
    },
    async deleteItemConfirm (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: this.stringFormat(this.$t('SubscribeMaint.confirmDelete'), item.name),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteNode(item)
          if (val !== undefined) {
            if (this.vDataTable.selected.indexOf(this.vDataTable.editedItem) >= 0) {
              this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
            }
          }
          this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEditWindow = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (this.vDataTable.editedIndex > -1) {
        await this.updateSubscribe(this.vDataTable.editedItem.skey,
          this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.email,
          this.vDataTable.editedItem.tel,
          this.vDataTable.editedItem.picture_base64,
          this.vDataTable.editedItem.active_flag)
      } else {
        await this.addSubscribe(this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.email,
          this.vDataTable.editedItem.tel,
          this.vDataTable.editedItem.picture_base64,
          this.vDataTable.editedItem.active_flag)
      }
      // this.close()
    },
    closeLanguage () {
      this.dialog.dialogLanguage = false
      this.$nextTick(() => {
        this.vDataTable.editedLanguageItem = Object.assign({}, this.vDataTable.defaultLanguageItem)
        this.vDataTable.editedLanguageIndex = -1
      })
    },
    pickFileInsert (image) {
      this.$refs.imageInsert.click()
    },
    handleFileSelectInsert: function (event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          var binaryData = e.target.result
          this.vDataTable.editedItem.tel = input.files[0].name
          this.vDataTable.editedItem.picture_base64 = window.btoa(binaryData)
        }
        reader.readAsBinaryString(input.files[0])
      }
    },
    resetDistrictSubdistrict () {
      // this.$swal({type: 'error', html: this.provinceId})
      this.vDataTable.editedItem.district_id = 0
      this.vDataTable.editedItem.sub_district_id = 0
    },
    resetSubdistrict () {
      this.vDataTable.editedItem.sub_district_id = 0
    },
    setZipcode () {
      let options = this.subDistrictList
      let zip = options.filter(o => o.id === this.vDataTable.editedItem.sub_district_id)
      zipcode = zip[0].zip_code
    },
    async uploadFile () {
      var isSuccess = true
      var msg = ''
      var isSave = false
      var i = 0
      var perCentComplete = 0
      for (i = 0; i < this.uploadDocumentList.length; i++) {
        if (this.uploadDocumentList[i].skey === -1) {
          this.dialog.dialogUpload = true
          this.progressMessage = this.$t('กรุณารอสักครู่กำลังอัพโหลดไฟล์ ' + this.uploadDocumentList[i].filename)
          let formData = new FormData()
          formData.append('skey', this.uploadDocumentList[i].skey)
          formData.append('register_skey', this.vDataTable.editedItem.register_skey)
          formData.append('upload_skey', this.uploadDocumentList[i].upload_skey)
          formData.append('remark', this.uploadDocumentList[i].remark)
          formData.append('file', this.uploadDocumentList[i].picture_base64)
          await this.axios.post(process.env.VUE_APP_API + '/register/uploadPicture' + process.env.VUE_APP_DATABASE_TYPE, formData,
            {
              headers: {'Content-Type': 'multipart/form-data'},
              onUploadProgress: function (progressEvent) {
                perCentComplete = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                this.uploadPercentage = perCentComplete
                if (i === this.uploadDocumentList.length && perCentComplete === 100) {
                  isSave = true
                  this.retrieveUploadDocument(this.vDataTable.editedItem.register_skey)
                  this.retrieveRegister()
                  this.dialog.dialogUpload = false
                  this.dialog.dialogAddEditWindow = false
                  // this.retrieveRegisterPartner()
                  this.$swal({type: 'success', title: 'อัพโหลดเรียบร้อยแล้ว', showConfirmButton: false, timer: 1500})
                }
              }.bind(this)
            }
          ).then(async response => {
            if (!response.data.isSuccess) {
              msg = msg + response.data.reasonText + '<br>'
            }
          })
          .catch(async e => {
            // this.$swal({type: 'error', title: e.message})
            msg = msg + response.data.reasonText + '<br>'
          })
        }
        perCentComplete = 0
      }
      this.$swal({type: 'success', title: this.successMsg, showConfirmButton: false, timer: 1500})
      this.retrieveUploadDocument(this.vDataTable.editedItem.register_skey)
      this.retrieveRegister()
      this.dialog.dialogUpload = false
      this.dialog.dialogAddEditWindow = false
    },
    Download (pathImage, filename) {
      this.axios.post(process.env.VUE_APP_API + '/register/getFileBase64', {
        path_image: pathImage
      }, {})
        .then(response => {
          if (response.data.isSuccess) {
            const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    DownloadZip () {
      var fileName = this.vDataTable.editedItem.first_name + '_' + this.vDataTable.editedItem.last_name + '.zip'
      if (this.vDataTable.editedItem.company_flag === 'Y') {
        fileName = this.vDataTable.editedItem.company_name + '.zip'
      }
      var registerSkey = this.vDataTable.editedItem.register_skey
      this.axios.post(process.env.VUE_APP_API + '/register/getZipFileBase64', {
        file_name: fileName,
        register_skey: registerSkey
      }, {})
        .then(response => {
          if (response.data.isSuccess) {
            const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/zip; charset=utf-8'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    validatePhoneNumber (myTelephone) {
      var ret = true
      if (!myTelephone.match(this.regTelephone)) {
        ret = false
      }
      return ret
    },
    validateIdcard (idCard) {
      var ret = true
      var sum = 0
      var i = 0
      if (!this.IsNumeric(idCard)) {
        ret = false
      } else if (idCard.substring(0, 1) === 0) {
        ret = false
      } else if (idCard.length !== 13) {
        ret = false
      } else {
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseFloat(idCard.charAt(i)) * (13 - i)
        }
        if ((11 - sum % 11) % 10 !== parseFloat(idCard.charAt(12))) {
          ret = false
        } else {
          ret = true
        }
      }
      return ret
    },
    IsNumeric (id) {
      return !isNaN(parseFloat(id)) && isFinite(id)
    },
    validateMail (myEmail) {
      var ret = true
      if (!this.regEmail.test(myEmail)) {
        ret = false
      }
      return ret
    },
    handleButtonRemarkClick () {
      // Handle button click functionality
      console.log('Button clicked!')
      this.dialog.dialogRemark = true
      console.log(this.time_remark)
      // You can access the textarea value using this.textAreaValue
    },
    async saveRemark () {
      if (!this.vDataTable.editedItem.register_skey) {
        this.$swal({type: 'error', title: 'กรุณาบันทึกข้อมูลก่อน'})
        return
      }
      if (!this.remark) {
        this.$swal({type: 'error', title: 'กรุณากรอกหมายเหตุ'})
        return
      }
      if (!this.date_remark) {
        this.$swal({type: 'error', title: 'กรุณาระบุวันที่หมายเหตุ'})
        return
      }
      await this.axios.post(process.env.VUE_APP_API + '/register/addRegisterRemark' + process.env.VUE_APP_DATABASE_TYPE, {
        register_skey: this.vDataTable.editedItem.register_skey,
        remark: this.remark,
        date_remark: this.date_remark + ' ' + this.time_remark,
        remark_old: this.vDataTable.editedItem.remark
      })
        .then(async response => {
          if (response.data.isSuccess) {
            this.$swal({type: 'success', title: response.data.reasonText})
            this.vDataTable.editedItem.remark = response.data.remark
            this.retrieveRegister()
            this.clearRemark()
            this.dialog.dialogRemark = false
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    clearRemark () {
      this.remark = null
      this.date_remark = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.time_remark = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
    },
    closeRemark () {
      this.dialog.dialogRemark = false
      this.clearRemark()
    },
  },
}
</script>
<style scoped>
.style-1 {
  color: rgb(215, 44, 44);
}
.style-2 {
  color: rgb(114,114,67);
}
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0,0,0,.12);
}
.input__label {
  color: rgb(54, 54, 54);
}
.my-text-style >>> .v-text-field__slot input {
  background: rgb(255, 206, 215);
}
.my-select-style >>> .v-text-field__slot select {
  background: rgb(255, 206, 215);
}
a { text-decoration: none; }
</style>